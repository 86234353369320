import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Link from '../core/Link';

import { StyledPressRelease, ArticleInfo, StyledBody } from './styles';

const PressRelease = ({ pressRelease }) => {
  const {
    headline,
    subheadline,
    subheadline2,
    modifiedDate,
    releaseDate,
    contact,
    pdf_url,
    body,
  } = pressRelease;

  return (
    <StyledPressRelease className="narrow press-release">
      <ArticleInfo>
        <div>
          {headline && <h1>{headline}</h1>}
          {subheadline && <h2>{subheadline}</h2>}
          {subheadline2 && <h3>{subheadline2}</h3>}
          {releaseDate && <time>{releaseDate}</time>}
        </div>
      </ArticleInfo>
      <div>
        {pdf_url && (
          // TODO replace with icons when Chris provide one
          <Link
            to={pdf_url}
            className="solid-on-dark-bg hvr-bounce-to-right"
            children="Download here"
          />
        )}
      </div>
      {body && <StyledBody dangerouslySetInnerHTML={{ __html: body }} />}
      {contact && <p>{contact}</p>}
    </StyledPressRelease>
  );
};

PressRelease.propTypes = {
  releaseId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default PressRelease;
