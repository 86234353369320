import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledPressRelease = styled.article`
  margin: 100px 0 60px 0;
  h1,
  h2,
  h3,
  p {
    letter-spacing: 0;
    text-align: left;
    padding: 20px 0;
  }
  h1 {
    color: ${themeGet('colors.spinTextDark')};
    font-family: ${themeGet('fonts.rubik')};
    font-weight: 900;

    font-size: 4.0625rem;
    line-height: 4.25rem;

    @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
      font-size: 3.25rem;
      line-height: 3.375rem;
    }
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      font-size: 2.5rem;
      line-height: 2.8125rem;
    }
  }
  h2 {
    font-size: 2.8125rem;
    line-height: 3.4375rem;
  }
  h3 {
    font-size: 1.875rem;
    line-height: 2.5rem;
    font-weight: 600;
  }
  ul li {
    margin-left: 5%;
    max-width: 85%;
  }
  p,
  ul,
  ol,
  li,
  span,
  em,
  b,
  time {
    color: ${themeGet('colors.spinTextDark')};
    font-family: ${themeGet('fonts.sans')};
    font-size: 1rem;
    line-height: 1.625rem;
  }
  img {
    margin: 26px 0;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    h1 {
      font-size: 2.5rem;
      line-height: 2.8125rem;
      letter-spacing: 0.29px;
    }
    h2 {
      font-size: 1.875rem;
      line-height: 2.1875rem;
    }
    h3 {
      font-size: 1.3125rem;
      line-height: 1.875rem;
    }
    p,
    ul,
    ol,
    li,
    span,
    em,
    b,
    time {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
    img {
      margin: 20px 0;
    }
  }
`;

export const ArticleInfo = styled.div`
  display: flex;
  time {
    display: block;
    margin: 12px 0;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    flex-wrap: wrap;
  }
`;

export const StyledBody = styled.div`
  margin: 31px 0 0;
`;

export const RippleLoading = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  left: 50%;
  transform: translateX(-40px);

  div {
    position: absolute;
    border: 4px solid ${themeGet('colors.spinTextDark')};
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`;
