import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { LocaleProvider } from '../components/core/Context/LocaleProvider';
import { SitemapProvider } from '../components/core/Context/SitemapProvider';
// import SEO from '../components/seo';
import SEO from '../components/core/SEO';
import Layout from '../components/layout';
import theme, { GlobalStyles } from '../themes';
import PressRelease from '../components/PressRelease';

const PressReleaseTemplate = ({ pageContext, data }) => {
  const { sitemap, parentPage, locale } = pageContext;
  const { locales } = data;
  const siteConfiguration = data.contentfulDevGlobalSiteConfiguration;
  return (
    <LocaleProvider.Provider value={{ code: locale }}>
      <SitemapProvider.Provider value={sitemap}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <SEO title={data.pressRelease.headline} locales={locales} />
          <Layout
            footer={siteConfiguration.globalFooter}
            siteConfiguration={siteConfiguration}
            locales={locales}
            locale={locale}
            parentPage={parentPage}
          >
            <PressRelease pressRelease={data.pressRelease} />
          </Layout>
        </ThemeProvider>
      </SitemapProvider.Provider>
    </LocaleProvider.Provider>
  );
};

PressReleaseTemplate.propTypes = {
  pageContext: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.oneOfType([PropTypes.object]),
    metaKeywords: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.object]),
    footer: PropTypes.oneOfType([PropTypes.object]),
    locale: PropTypes.oneOfType([PropTypes.object]),
    locales: PropTypes.oneOfType([PropTypes.object]),
    sitemap: PropTypes.oneOfType([PropTypes.object]),
    siteConfiguration: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
};

export default PressReleaseTemplate;

export const query = graphql`
  query PressReleaseTemplate($id: String!) {
    contentfulDevGlobalSiteConfiguration {
      ...SiteConfiguration
    }
    locales: allContentfulDevGlobalSiteConfiguration {
      nodes {
        node_locale
        languageSwitcherText
      }
    }
    pressRelease(id: { eq: $id }) {
      id
      headline
      summary
      subheadline2
      subheadline
      contact
      body
      active
      image_caption
      language
      location
      modified
      modifiedDate
      origin
      pdf_url
      pull_quote
      releaseDate
      released
      url
    }
  }
`;
